import ApplicationController from "./application_controller";
import { checkRequiredFields } from "../utils/check_required_fields";
import { removeError } from "../utils/form_validator";

export default class extends ApplicationController {
  updateField(event) {
    const el = document.querySelector(event.target.dataset.selector);
    el.classList.toggle('vanish');
    const formElements = el.querySelectorAll("input, select, textarea, button");

    if (event.target.checked) {
      checkRequiredFields(el, true);
      formElements.forEach(element => {
        element.disabled = false;
      });
      // If is needed since we reset checkboxes on close
      if (event.currentTarget.id != "agent-change-reason-checkbox") this.showActiveMessagesOnOpen(event.currentTarget);
    } else {
      checkRequiredFields(el, false);
      removeError(event, el)
      formElements.forEach(element => {
        element.disabled = true;
      });
      this.hideMessagesOnClose(event.currentTarget);
    }
  }

  hideMessagesOnClose(el) {
    const mentorFormCheckboxContainer = el.parentElement.parentElement;
    const messages = mentorFormCheckboxContainer.querySelectorAll('.mentor-form--checkbox-container--subform-container--message:not(.vanish)');
    messages.forEach(message => {
      message.classList.add('vanish');
      message.dataset.isActive = "true";
    });
  }

  showActiveMessagesOnOpen(el) {
    const mentorFormCheckboxContainer = el.parentElement.parentElement;
    const messages = mentorFormCheckboxContainer.querySelectorAll('.mentor-form--checkbox-container--subform-container--message[data-is-active="true"]');
    messages.forEach(message => {
      message.classList.remove('vanish');
    });
  }

  toggleWarning(e) {
    let checkedArr = [];
    Array.from(document.querySelectorAll(e.target.dataset.classSelector)).forEach((el) => {
      checkedArr.push(el.checked);
    });

    const warningEl = document.querySelector(e.target.dataset.warningSelector);

    if (checkedArr.includes(true)) {
      warningEl.classList.remove('vanish');
    } else {
      warningEl.classList.add('vanish');
    }
  }

  resetCheckboxes() {
    Array.from(document.querySelectorAll('.agent-change-reason-checkbox')).forEach((el) => {
      el.checked = false;
    });
  }
}
