import ApplicationController from "./application_controller";
import { hideElement, showElement } from "../utils/show_hide_el";

export default class extends ApplicationController {
  updateFeeWaiverTypeField(event) {
    const value = event.target.value;

    const waiverTypeFeeContainer = document.querySelector("#mpm_waiver_type_fee_container");
    const waiverTypeFeeEl = document.querySelector("#mpm_waiver_type_fee");
    const waiverNotesFeeContainerEl = document.querySelector("#mpm_waiver_notes_fee_container");
    const waiverNotesFeeEl = document.querySelector("#mpm_waiver_notes_fee");

    const waiverNotesProgramContainerEl = document.querySelector("#mpm_waiver_notes_program_container");
    const waiverNotesProgramEl = document.querySelector("#mpm_waiver_notes_program");

    const waiverFeeWarningEl = document.querySelector(".waiver-fee--warning");
    const disabledMessages = document.querySelectorAll(".disabled-message");
    if (value === 'Fee Waiver') {
      showElement(waiverTypeFeeEl, waiverTypeFeeContainer);
      showElement(waiverNotesFeeEl, waiverNotesFeeContainerEl, false);
      super.removeVanish(waiverFeeWarningEl);
      disabledMessages.forEach(el => {
        super.addVanish(el);
      })
      hideElement(waiverNotesProgramEl, waiverNotesProgramContainerEl, false);
      this.enableCheckboxes();
    } else if (value === 'Program Waiver') {
      hideElement(waiverTypeFeeEl, waiverTypeFeeContainer);
      hideElement(waiverNotesFeeEl, waiverNotesFeeContainerEl, false);
      super.removeVanish(waiverFeeWarningEl);

      showElement(waiverNotesProgramEl, waiverNotesProgramContainerEl, false)
      
      this.stimulate('MentorWaiverOptionsReflex#reset_fields')

    } else {
      hideElement(waiverTypeFeeEl, waiverTypeFeeContainer);
      hideElement(waiverNotesFeeEl, waiverNotesFeeContainerEl, false);
      hideElement(waiverNotesProgramEl, waiverNotesProgramContainerEl, false);
      disabledMessages.forEach(el => {
        super.addVanish(el);
      })
      super.addVanish(waiverFeeWarningEl);
      this.enableCheckboxes();
    }
  }

  enableCheckboxes() {
    const checkboxesSelectors = [
      '#agent-change-reason-checkbox',
      '#pairing-request-checkbox',
      '#time-request-checkbox'
    ]

    checkboxesSelectors.forEach((selector) => {
      let checkbox = document.querySelector(selector);
      checkbox.removeAttribute('disabled');
    });
  }
}
