export function validateForm(formSelector) {  
  let formIsValid = true;
  const form = document.querySelector(formSelector);
  const requiredInputs = form.querySelectorAll('input[required], select[required], textarea[required]');

  requiredInputs.forEach((el) => { 
    if (!el.required || el.validity.valid) {
      el.classList.remove('has-errors');
      return
    };
    

    formIsValid = false;
    addErrors(el);
  });


  const checkboxElParents = document.querySelectorAll(".validate_at_least_one_checkbox");

  checkboxElParents.forEach(parentEl => {
    if (parentEl.querySelectorAll("input[type=checkbox]:checked").length === 0 && parentEl.querySelectorAll("input[type=checkbox]:disabled").length === 0) {
      formIsValid = false;
      addErrors(parentEl, "Please select at least one option");

      parentEl.querySelectorAll("input[type=checkbox]").forEach(element => {
        element.addEventListener('click', (e) => removeError(e, parentEl));
      });
    }
  });

  return formIsValid;
}

export function addErrors(el, message = null) {
  let targetEl = el.classList.contains('hidden-multiselect') ? el.parentElement : el;
  targetEl.classList.add('has-errors');

  let possibleErrorMessage = targetEl.nextElementSibling;
  let isErrorMessage = possibleErrorMessage?.classList?.contains("input-error-message");
  if (!isErrorMessage) targetEl.insertAdjacentHTML('afterend', `<span class="input-error-message">${el.validationMessage ?? message}</span>`);

  el.addEventListener('focus', (e) => removeError(e, targetEl));
  el.addEventListener('keydown', (e) => removeError(e, targetEl));
}

export function removeError(e, targetEl) {
  targetEl.classList.remove('has-errors');
  const parentEl = targetEl.parentElement;
  parentEl.querySelectorAll(".input-error-message").forEach(el => el.remove());
}