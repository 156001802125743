import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  getAgentData(event) {
    event.preventDefault();
    this.fetchData(
      "#mpm_agent_id",
      "#mpm_agent_id_container",
      {
        email: "#mpm_agent_email",
        first_name: "#mpm_agent_name_first",
        last_name: "#mpm_agent_name_last",
        active_date: "#agent_active_date",
        uuid: '#agent_uuid',
        system_key: '#system_key',
        exp_enterprise_id: '#exp_enterprise_id',
      },
      "#active-agent-error",
      "/mentor/agents/",
      "No active agent found",
      "Something went wrong with finding the agent agent"
    );
  }

  getMentorData(event) {
    event.preventDefault();
    this.fetchData(
      "#mpm_mentor_agent_id",
      ".certified-mentor--input--container",
      {
        first_name: "#mpm_mentor_agent_first_name",
        last_name: "#mpm_mentor_agent_last_name"
      },
      "#pairing-request-error",
      "/mentor/mentor_agents/",
      "No mentor agent found",
      "Something went wrong with finding the mentor agent",
      "#pairing-request-warning"
    );
  }

  fetchData(idSelector, containerSelector, fieldSelectors, errorSelector, urlPrefix, notFoundError, generalError, warningSelector = null) {
    const idField = document.querySelector(idSelector);
    const container = document.querySelector(containerSelector);
    const errorEl = document.querySelector(errorSelector);
    const inputSubmitBtn = container.querySelector(".input--submit-container--btn");
    const inputSubmitBtnText = container.querySelector(".input--submit-container--btn--text");
    const inputSubmitBtnLoader = container.querySelector(".input--submit-container--btn--loader");
    const agentStatusField = document.querySelector("#mpm_agent_status")

    inputSubmitBtnText.textContent = "Submitting...";
    inputSubmitBtnLoader.classList.remove("vanish");

    fetch(`${urlPrefix}${idField.value}?status=${agentStatusField.value}`)
      .then(response => response.json())
      .then(data => {
        if (data === null) {
          // Handle null data if necessary
        } else if (data.error) {
          if (data.error === notFoundError) {
            if (warningSelector) {
              document.querySelector(warningSelector).classList.add('vanish');
            }
            errorEl.classList.remove('vanish');
            idField.classList.add("input--has-error");
            inputSubmitBtnText.textContent = "Error";
          } else {
            console.error(data.error);
            inputSubmitBtnText.textContent = "Submit";
            super.renderToast("body", generalError, "danger", false, 6000);
          }
        } else {
          for (const [key, selector] of Object.entries(fieldSelectors)) {
            document.querySelector(selector).value = data[key] || "";
          }
          errorEl.classList.add('vanish');
          idField.classList.remove("input--has-error");
          inputSubmitBtnText.textContent = "Submitted";
          inputSubmitBtn.classList.add("is-submitted");
        }
      }).finally(() => {
        inputSubmitBtnLoader.classList.add("vanish");
      });
  }
}