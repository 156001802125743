import ApplicationController from "./application_controller";
import { validateForm } from "../utils/form_validator";

export default class extends ApplicationController {
  enableSubmit(event) {
    const submitBtn = document.querySelector("#form-submit-btn");

    if (event.target.checked) {
      submitBtn.removeAttribute("disabled");
    } else {
      submitBtn.setAttribute("disabled", true);
    }
  }

  ensureOneWaiverCheckboxIsSelectedAndValidateForm(e) {
    const waiverCheckboxes = document.querySelectorAll(".mentor-form--checkbox-container--main-checkbox--input");
    let oneIsSelected = false;
    waiverCheckboxes.forEach(checkbox => {
      if (checkbox.checked) {
        oneIsSelected = true;
      }
    });
    if (!oneIsSelected) return this.renderToast("body", "Please select at least one of the checkbox options", "danger", false, null, 10000, true);
    
    const isValidForm = validateForm("#mentor_form");
    if (!isValidForm) return this.renderToast("body", "One or more submission requirements have not been met", "danger", false, null, 10000, true);
  
    const submitBtn = document.querySelector("#form-submit-btn");
    const existingModal = document.getElementById(submitBtn.dataset["modal-ConfirmModal-ConfirmModalSelectorIdValue"]);
  
    if (existingModal) {
      document.body.classList.add("modal-is-open");
      if (existingModal.dataset["modal-ModalStopScrollValue"] == "true") document.body.classList.add("stop-scroll");
      return existingModal.classList.add('is-active');
    }
  
    this.stimulate(
      `${submitBtn.dataset["modal-ConfirmModal-ConfirmModalConfirmReflexClassValue"]}#${submitBtn.dataset["modal-ConfirmModal-ConfirmModalConfirmReflexActionValue"]}`,
      submitBtn.dataset["modal-ConfirmModal-ConfirmModalSelectorIdValue"],
      submitBtn.dataset["modal-ConfirmModal-ConfirmModalIconValue"],
      submitBtn.dataset["modal-ConfirmModal-ConfirmModalMessageValue"],
      submitBtn.dataset["modal-ConfirmModal-ConfirmModalModalTitleValue"],
      submitBtn.dataset["modal-ConfirmModal-ConfirmModalClassesValue"],
      submitBtn.dataset["modal-ConfirmModal-ConfirmModalIsHtmlMessageValue"],
      submitBtn.dataset["modal-ConfirmModal-ConfirmModalConfirmReflexActionOptionsValue"]
    );
  }
}
