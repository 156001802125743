import ApplicationController from "./application_controller";
import { showElement, hideElement } from "../utils/show_hide_el";
import { checkRequiredFields } from "../utils/check_required_fields";

export default class extends ApplicationController {
  updateFields(event) {
    const selectedValue = event.target.value;

    const agentFieldPlaceholder = document.querySelector("#mpm_agent_id_container")
    const agentField = document.querySelector("#mpm_agent_id")
    const emailFieldPlaceholder = document.querySelector("#mpm_agent_email_container")
    const agentDataRow = document.querySelector("#agent_data_row")
    const agentNameFields = document.querySelectorAll(".mpm-agent--name-field")
    const activeAgentError = document.querySelector("#active-agent-error")
    
    agentDataRow.querySelectorAll(".has-errors").forEach(el => {
      el.classList.remove("has-errors");
    });
    activeAgentError.classList.add("vanish");
    if (selectedValue === "") {
      agentDataRow.classList.add('vanish');
      hideElement(agentField, agentFieldPlaceholder);
      emailFieldPlaceholder.classList.add('vanish');

      checkRequiredFields(agentDataRow, false);
    } else {
      agentDataRow.classList.remove('vanish');
      checkRequiredFields(agentDataRow, true);

      if (selectedValue === "Pre-Join") {
        hideElement(agentField, agentFieldPlaceholder);
        emailFieldPlaceholder.classList.remove('vanish');

        agentNameFields.forEach(el => {
          el.classList.remove("disabled");
        });
      } else {
        showElement(agentField, agentFieldPlaceholder);
        emailFieldPlaceholder.classList.add('vanish');

        agentNameFields.forEach(el => {
          el.classList.add("disabled");
        });
      }
    }
  }
}
