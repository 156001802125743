import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  setValue(e) {
    e.currentTarget.setAttribute("value", e.currentTarget.value);
  }
  clearInputError() {
    this.element.classList.remove("input--has-error");
    const inputSubmitContainer = this.element.parentElement;
    inputSubmitContainer.querySelector(".input--submit-container--btn").classList.remove("is-submitted");
    inputSubmitContainer.querySelector(".input--submit-container--btn--text").textContent = "Submit";
  }

}