import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  toggleMentorFields() {
    const pairingRequestReasonEl = document.querySelector("#mpm_pairing_request_reason");
    const pairingWarningEl = document.querySelector("#pairing-request-warning"); 
    const mentorAgentDataContainer = document.querySelector("#mentor-agent-data-container");


    if (pairingRequestReasonEl.value === "") {
      pairingWarningEl.classList.add('vanish');
      mentorAgentDataContainer.classList.add('vanish');
    } else {
      pairingWarningEl.classList.remove('vanish');
      mentorAgentDataContainer.classList.remove('vanish');
    }
  }
}
