export function checkRequiredFields(el, required) {
  const formElements = el.querySelectorAll("input, select, textarea, button");
  
  const elIdsWithCheckboxes = ['time-request-container', 'agent-change-reason-container']

  if (required) {
    formElements.forEach(element => {
      if (!element.classList.contains('optional_field')) {
        element.required = true;
      }
    });

    if (elIdsWithCheckboxes.includes(el.id)) {
      el.classList.add('validate_at_least_one_checkbox')
    }
  } else {
    formElements.forEach(element => {
      element.required = false;
    });
    if (elIdsWithCheckboxes.includes(el.id)) {
      el.classList.remove('validate_at_least_one_checkbox')
    }
  }
}
