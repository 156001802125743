export function hideElement(el, container, required = true) {
  container.classList.add('vanish');
  if (required) el.removeAttribute('required');
  el.setAttribute('disabled', true);
}

export function showElement(el, container, required = true) {
  container.classList.remove('vanish');
  if (required) el.setAttribute('required', true);
  el.removeAttribute('disabled');
}
